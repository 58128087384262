.left-side-bar-main-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 242px;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 10px;
  background: #ffffff;
  border: solid 0px #dddddd;
  border-right: solid 0.5px #ebebeb;

  will-change: transform;
  transition: all 0.2s ease-in-out;

  position: relative;
}

.left-side-minimized {
  margin-left: -240px;
}

@media (max-width: 1300px) {
  .left-side-bar-main-box {
    height: calc(100vh - 15px);
  }
}

.left-side-bar-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 240px;
}

.nav-link-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-bottom-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.nav-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 240px;
  height: 50px;

  padding-left: 20px;
  padding-right: 16px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.01em;

  align-items: center;

  color: #0d4d57;
  cursor: pointer;
  background: #ffffff;
}

.nav-button-box:hover {
  color: #0d4d57;
  background: #e4eaee;
  transition: 'width' 0.2s ease;
}

.nav-button-box-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 240px;
  height: 50px;

  padding-left: 20px;
  padding-right: 16px;
  background-color: #0d4d57;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.01em;

  color: white;
  cursor: pointer;
  align-items: center;
}

.nav-button-inner-box-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  padding-left: 40px;

  background-color: #d8e7f3;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.01em;

  border: 1px solid #f8f8f8;
  color: #0c40a5;
  cursor: pointer;
}

.nav-button-inner-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  padding-left: 40px;
  padding-right: 16px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.01em;

  border: 1px solid #f8f8f8;
  color: #575757;
  cursor: pointer;
}

.nav-button-inner-box:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  background-color: #d8e7f3;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.01em;

  border: 1px solid #f8f8f8;
  color: #0c40a5;
  cursor: pointer;
}

.nav-button-dropped {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  padding-left: 20px;
  padding-right: 16px;
  background-color: #ffffff;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.01em;

  border: 1px solid #f8f8f8;
  color: #0c40a5;
  cursor: pointer;
}

.nav-button-dropped:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  padding-left: 20px;
  padding-right: 16px;
  background-color: #d8e7f3;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  letter-spacing: 0.01em;

  border: 1px solid #f8f8f8;
  color: #0c40a5;
  cursor: pointer;
}

.nav-signout-button {
  display: flex;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  color: #000;
}

.nav-signout-button:hover {
  display: flex;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #d8e7f3;
  border: 1px solid #f8f8f8;
  color: #0c40a5;
  cursor: pointer;
}

.nav-bottom-time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito Sans;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
}
.nav-bottom-time-format {
  font-style: bold;
  font-weight: 900;

  color: #000000;
}

.nav-signout-button-caption {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

/* .left-side-bar-guide {
  padding: 10px;
  margin: 5px;
  font-family: Nunito Sans;
  font-weight: 900;
  font-size: 13px;
  border-radius: 5px;
  border: solid 1px rgb(78, 68, 208);
} */
.left-side-bar-guide {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 0.8em;
  color: #345b7d;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 1em;
  padding-top: 1em;
  height: 130px;
  width: 240px;
}

.left-side-bar-guide img {
  margin-right: 3px;
}

.left-side-bar-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  height: calc(100vh - 300px);
  width: 252px;
  top: 230px;
}

.left-side-bar-items::-webkit-scrollbar {
  width: 0px;
}

.left-side-bar-logo-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100px;
  width: 240px;
}

.nav-button-caption-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 20px;
}
.nav-button-box-minimize {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button-box-plus {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
  border: solid 2px #0d4d57;
  color: #0d4d57;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0px !important;
}

.nav-button-box-plus-selected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
  border: solid 2px #ffffff;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0px !important;
}

.nav-button-box-plus:hover {
  opacity: 0.7;
}

.left-side-bar-minimize-button {
  will-change: transform;
  transition: all 0.2s ease-in-out;
  position: fixed;
  z-index: 1000;
  left: 240px;
  top: 135px;
  width: 20px;
  height: 1.78575rem;
  background: #fff;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px 0 0 2px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-right: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-side-bar-minimize-button:hover {
  cursor: pointer;
  width: 30px;
}

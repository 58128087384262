.top-nav-noti-bell-active {
  width: 24px;
  height: 24px;
  background: transparent;
  border: solid 0px;
  cursor: pointer;
  opacity: 1;
}

.top-nav-noti-bell-active:hover {
  cursor: pointer;
  opacity: 0.7;
}

.top-nav-noti-bell-non-active {
  width: 24px;
  height: 24px;
  filter: grayscale(0.6);
  opacity: 0.5;
}

.top-nav-noti-container {
  display: flex;
  height: 24px;
  align-items: center;
}
.top-nav-noti-container ul {
  margin: 0px;
  padding: 0px;
}

.top-nav-noti-container button {
  display: flex;
  align-items: center;
}

.top-nav-noti-bell-mark-active {
  left: -14px;
  top: -10px;
  position: relative;

  width: 18px;
  height: 18px;

  border-radius: 50%;
  background-color: red;
  border-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.top-nav-noti-bell-mark-disabled {
  width: 16px;
  height: 16px;
}

.account-setting-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .container-orders {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-orders {
    width: 1140px;
    max-width: 100%;
  }
}

.page-content {
  padding-top: 20px;
  padding-bottom: 200px;
}

@media (min-width: 768px) {
  .page-content {
    padding-top: 80px;
  }
}

.setting-page-title {
  color: #343434;
  margin-top: 20px;
}

.setting-form {
  padding-bottom: 100px;
  max-width: 960px;
  margin: 0 auto;
}

.setting-form--sm {
  max-width: 570px;
}

@media (min-width: 992px) {
  .setting-form {
    padding-bottom: 200px;
  }
}

@media (max-width: 991px) {
  .setting-form .form-group-action .btn {
    width: 100%;
    margin: 10px 0;
  }
}

@media (min-width: 992px) {
  .setting-form .form-group-action .btn {
    min-width: 275px;
  }
}

label {
  text-transform: none;
  color: #4c5460;
  font-family: 'Open Sans', sans-serif;
  vertical-align: top;
  line-height: 1;
}

label:not(.custom-control) {
  font-size: 0.8125rem;
}

.form-group .label-focused {
  top: 6px !important;
  font-size: 14px !important;
  opacity: 1 !important;
}

.form-group .form-control {
  line-height: 1.25rem;
  padding: 0.625rem 0.75rem;
  height: unset;
}

.btn-link {
  background: transparent;
  outline: 0px;
  border: 0px;
}
.btn-link:focus {
  outline: 0px !important;
  border: 0px;
  box-shadow: none;
}

.settings-btn {
  min-width: 270px;
}

.top-nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
}

.top-nav-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.top-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 3;
}

.top-nav-logo-image {
  padding-left: 10px;
  padding-right: 10px;
}

.top-nav-line {
  border: none;
}

.top-nav-title-box {
  padding-left: 27px;
  padding-right: 27px;
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 100;
  font-size: 20px;
  line-height: 41px;
  /* identical to box height */
  color: #000;
}

@media (max-width: 1100px) {
  .top-nav-left {
    display: none;
  }
}

.top-nav-title-bold {
  font-family: Nunito Sans;
  font-weight: 900;
  font-size: 20px;
  line-height: 41px;
  /* identical to box height */
  color: #0041c8;
}

.top-nav-title-normal {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 100;
  font-size: 28px;
  line-height: 41px;
  /* identical to box height */
  color: #000;
}

.top-nav-search {
  display: flex;
  max-width: 600px;
  min-width: 300px;
  width: 100%;
  height: 28px;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
}

.top-nav-search-box {
  height: 28px;
  border-radius: 9px 0px 0px 9px;
}

.top-nav-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 32px;
  cursor: pointer;
  background: #0c40a5;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 0px 9px 9px 0px;
  z-index: 0;
  cursor: pointer;
  outline: none;
}

.top-nav-search-button:hover {
  background: #001643;
}
.top-nav-magnifier {
  width: 25px;
  height: 25px;
}

.top-nav-user-pic {
  margin-right: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-nav-user-pic button {
  padding: 0px;
}

.top-nav-user-pic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: solid 2px #0c40a5;
  border-radius: 50%;
}

.top-nav-user-pic-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 50%;
}

.top-nav-user-pic-container:hover {
  border: solid 2px #83a4e4;
}

.top-nav-user-name {
  font-weight: bold;
  font-size: 15px;
}

.top-nav-form-control {
  width: 100%;
  height: 32px;
  line-height: 19px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 9px 0px 0px 9px;

  font-family: Nunito Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #1f2b36;
  padding: 0px 0px 0px 20px;
}

.top-nav-form-control:focus {
  color: #1f2b36;
  background-color: #fff;
  border: solid 2px #fe695d;
  border-right-color: transparent;
  outline: 0;
  padding: 0px 0px 0px 20px;
}

.top-nav-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 999;
  flex: 1;
}
